<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <ManufacturerMenu></ManufacturerMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-2">
        <v-row v-if="userDetails.isActive" class="rowBg pa-5">
          <v-col
            cols="12"
            md="3"
            offset-md="1"
            style="background-color:#ffffff;border-radius:10px;"
          >
            <v-row wrap justify-center>
              <v-col cols="12">
                <v-row>
                  <v-icon size="50">mdi-clipboard-text-clock-outline</v-icon>
                </v-row>

                <v-row>
                  <h3 class="mt-3" justify-center align="center">
                    {{ manufacturesProducts.length }}
                  </h3>
                </v-row>
              </v-col>
            </v-row>
            <v-btn
              block
              class="buttonStyle mt-5"
              :to="'/0/manufacturers/products'"
              >All Products</v-btn
            >
          </v-col>

          <v-col
            cols="12"
            md="3"
            offset-md="1"
            style="background-color:#ffffff;border-radius:10px;"
          >
            <v-row wrap justify-center>
              <v-col cols="12">
                <v-row>
                  <v-icon size="50">mdi-clipboard-text-clock-outline</v-icon>
                </v-row>

                <v-row>
                  <h3 class="mt-3" justify-center align="center">0</h3>
                </v-row>
              </v-col>
            </v-row>
            <v-btn
              block
              class="buttonStyle mt-5"
              to="/en/manufacturers/0/orders-list"
              >Track Order</v-btn
            >
          </v-col>

          <v-col
            cols="12"
            md="3"
            offset-md="1"
            style="background-color:#ffffff;border-radius:10px;"
          >
            <v-row wrap justify-center>
              <v-col cols="12">
                <v-row>
                  <v-icon size="50">mdi-clipboard-text-clock-outline</v-icon>
                </v-row>

                <v-row>
                  <h3 class="mt-3" justify-center align="center">0</h3>
                </v-row>
              </v-col>
            </v-row>
            <v-btn block class="buttonStyle mt-5">Total Orders</v-btn>
          </v-col>
        </v-row>

        <!--Recent ManufacturersProduct Products-->
        <v-row v-if="userDetails.isActive" class="mt-4 mb-5">
          <v-col cols="12">
            <h5>Recently added products</h5>
            <v-row
              wrap
              class="pa-5"
              style="background-color: #f2f2f2"
              v-if="manufacturesProducts.length >= 1"
            >
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Product Name
                        </th>
                        <th class="text-left">
                          Category
                        </th>
                        <th class="text-left">
                          Quantity
                        </th>
                        <th class="text-left">
                          Price
                        </th>
                        <th class="text-left">
                          Prescription Status
                        </th>
                        <th class="text-left">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in manufacturesProducts"
                        :key="item.productId"
                      >
                        <td>{{ item.name }}</td>
                        <td>{{ item.ProductCategory.name }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>
                          <b
                            >{{ systemSetting.systemCurrency
                            }}{{ item.price }}</b
                          >
                        </td>
                        <td>
                          <span v-if="item.needsPrescription == false" style="color:red;"
                            >No</span
                          >
                          <span
                            v-if="item.needsPrescription == true"
                            style="color:green;"
                            >Yes</span
                          >
                        </td>
                        <td>
                          <span v-if="item.isActive == false" style="color:red;"
                            >Pending</span
                          >
                          <span
                            v-if="item.isActive == true"
                            style="color:green;"
                            >Active</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-row wrap class="pa-10" v-if="manufacturesProducts.length <= 0">
              <v-col cols="12" align="center" justify="center">
                <p>
                  <v-img
                    alt="System Logo"
                    class="shrink mr-2"
                    contain
                    :src="'img/emptyProductList.gif'"
                    transition="scale-transition"
                    width="350"
                  />
                </p>
                <p class="text-h5">
                  No Product Available
                </p>
              </v-col>
            </v-row>

            <!---- Edit Member Profile---->
          </v-col>
        </v-row>

        <v-row wrap v-else class="mb-10">
          <v-col cols="12" align="Center">
              <v-layout wrap>
                <v-col cols="12" align="Center">
                  <v-icon size="200" color="red">mdi-alert-circle</v-icon>
                </v-col>
              </v-layout>
              <h3>Account awaiting approval</h3>
              <v-layout wrap>
                <v-col cols="12" md="10" offset-md="1" align="Center">
                   <p>Hello <b>{{userDetails.name}}</b>! We are glad to have you sign up for qikPharma. Your account is currently under review and will be approved soon than you can imagine.</p>
                  
                </v-col>
              </v-layout>
             
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ManufacturerMenu from "../others/ManufacturerMenu.vue";
import { Country } from "country-state-city";

export default {
  name: "ManufactureDashboard",

  components: {
    ManufacturerMenu,
  },
  created() {
    this.user_id = localStorage.getItem("userId");
    this.getCountry();
    this.getUserDetails();
    this.getManufacturesProducts();
  },
  data: () => ({
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "My Account",
        disabled: false,
        href: "/",
      },
      {
        text: "Overview",
        disabled: true,
        href: "/overview/distributor_dashboard/en/home",
      },
    ],
    country: [],
    manufacturesProducts: [],
    systemSetting: {
      systemCurrency: "GH₵",
    },
    userDetails:{
      isActive:true,
    },
    user_id: "",
  }),
  methods: {
     getUserDetails() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + localStorage.getItem("userId"))
        .then((response) => {
          this.userDetails = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getCountry() {
      this.country = Country.getAllCountries();
    },
    getManufacturesProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product/user/` + localStorage.getItem("userId"))
        .then((response) => {
          if (response.data.success == true) {
            this.manufacturesProducts = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style scoped>
.priceStyle2 {
  width: 80px;
  height: auto;
  background-color: #bd2121;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
}

.iconPadding {
  padding: 50px !important;
}

.iconStyle {
  color: yellowgreen;
  font-size: 45px;
}
</style>
